import React from "react"
import { graphql, PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { Heading, ListItem, Meta, UnorderedList, ShareButtons } from "../components"
import { SEO } from "../components/Seo/Seo"


const Work: React.FC<PageProps & TalksProps> = () => {
  return (
    <PageLayout>
      <Container>
        <Meta title="Work" />
        {/* <Heading level="heading2">Intro</Heading>
        <Paragraph>
        I worked on different sectors like financial markets, agricultural automation and cybersecurity. <br />
        Down below some of my relevant works <br />
        </Paragraph> */}

        <Section>
          <Heading level="heading2">As a Developer on Axur Cybersecurity</Heading>
          <UnorderedList>
            Relevant works
            <br/>
            <ListItem>
            Promoted and applied microservice endpoint load-test using Python Locust, resulting in data-driven choice for upper limit endpoint parameters.
            </ListItem>
            <ListItem>
            Reduced cloud costs migrating 100s of Terabytes from S3 Standard to S3 Glacier.
            </ListItem>
            <ListItem>
            Achieved zero-downtime high impact migrations and security updates for internal containerized third-party services.
            </ListItem>
            <ListItem>
            Developed multithreaded socket java microservice for thousands of sensible metric signals, increasing throughput capability in ~100x from few thousands to hundreds of thousands packets per second with zero-downtime deployment.
            </ListItem>
          </UnorderedList>
        </Section>
        <br/>

        <Section>
          <Heading level="heading2">As a Developer on Asimov Trading</Heading>          
          <UnorderedList>
            Relevant works
            <br/>
            <ListItem>
            Implemented simple mean revert strategy with hyperparameter optimization.
            </ListItem>
            <ListItem>
            Optimized strategy compute time using better algorithms.
            </ListItem>
            <ListItem>
            Applied websocket latency reduction research using cold/hot algorithm for different websockets.
            </ListItem>
            <ListItem>
            Developed an infrastructure dashboard using Dash(Python) for trading services health monitoring across multiple clouds using Fabric(Python and bash).
            </ListItem>
          </UnorderedList>
        </Section>
        <br/>

        <Section>
          <Heading level="heading2">As a Developer at Elysios Smart Agriculture</Heading>
          <UnorderedList>
            Relevant works
            <br/>
            <ListItem>
            Created Systemd daemon to remote update java applications on distributed raspberry pis, eliminating manual ssh.
            </ListItem>
            <ListItem>
            Upgraded search algorithm to fuzzy search, boosting accuracy and user experience.
            </ListItem>
            <ListItem>
            Applied a polygon drawing algorithm for satellite map images for android and apple (xamarin).
            </ListItem>            
            <ListItem>
            Implemented authenticated APIs for weather data using Python Flask.
            </ListItem>
            <ListItem>
            Developed Cookie cutter templates for some standarized components, reducing component code setup time.
            </ListItem>                        
          </UnorderedList>
        </Section>
        <br/>

        <Section>
          <Heading level="heading2">As a Developer at Nelogica</Heading>
          <UnorderedList>
            Relevant works
            <br/>
            <ListItem>
            Optimized the order detection algorithms(Order Books, Times and Trades), for optimized render and real-time button replacement.
            </ListItem>
            <ListItem>
            Developed several new indicators for the platform such as the Market Profile with a novel heatmap coloring.
            </ListItem>
            <ListItem>
            Promoted and implemented a novel News platform, creating a new form of revenue through plugin subscription from diferent news sources affecting millions of users.
            </ListItem>
            <ListItem>
            Performed massive refactor on legacy code using a set of complex regexes with wildcards.
            </ListItem>                        
          </UnorderedList>
        </Section>
      </Container>
      <ShareButtons/>
    </PageLayout>
  )
}

type TalksProps = {
  data: { 
    rdd: { 
      publicURL: string 
    }; 
    rddUk: { 
      publicURL: string 
    } 
  }
}

const Container = styled("section", {
  maxWidth: 640,
  length: 0
})

const Section = styled("section", {
  length: 0
})

export const query = graphql`
  query {
    rdd: file(relativePath: { eq: "content/matheus-tura-resume.pdf" }) {
      publicURL
    }
    rddUk: file(
      relativePath: { eq: "content/matheus-tura-resume.pdf" }
    ) {
      publicURL
    }
  }
`

export default Work

export const Head = () => (
  <SEO title="work" pathname="/work"/>
)

